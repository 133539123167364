#msCart th, #msCart td {text-align:center; line-height: 30px;}
#msCart .title {text-align:left;}
#msCart th.total {text-align:right; padding-right:5px;}
.js #msCart [type="submit"][value="cart/change"] {display: none;}

#msMiniCart {font-size: .8em;}
#msMiniCart h5 {margin: 0;}
#msMiniCart .empty {display:block;}
#msMiniCart .not_empty {display: none;}
#msMiniCart.full .empty {display:none;}
#msMiniCart.full .not_empty {display: block;}

#msGallery {text-align: center;}
#msGallery #mainImage {margin-bottom: 10px; padding: 2px; border: 1px solid #ddd;}
#msGallery .thumbnails {width: 405px; margin:auto;}
#msGallery .span2 {margin-left: 10px; width: 120px; padding: 2px;}

#msOrder .error {border: 1px solid brown !important;}
#msOrder .required-star {color: brown;}

.ms2_product .price {
	padding-left: 50px;
	padding-right: 30px;
}
.ms2_product .old_price {padding-right: 30px;}
.ms2_product .tags, .ms2_product .color, .ms2_product .size  {
	padding-right: 20px;
	font-size: 0.9em;
}

.ms2_product .old_price, #msProduct .old_price, #msCart .old_price {
	color: brown;
	font-style: italic;
	text-decoration: line-through;
}

.msProductsLog, .msGalleryLog {
	width: 100%;
	overflow: auto;
	word-wrap: normal;
	white-space: pre;
}

#msOrder {margin-top:50px;}
#msOrder .radio {min-height: 30px;margin-bottom: 10px;}
#msOrder .radio img {max-height:30px;margin-top:-2px;padding: 0 10px;}
#msOrder .required-star {display: none;}
#msOrder .required .required-star {display: inline;}

.ms2-message-success  {background-color: green !important;}
.ms2-message-error  {background-color: brown !important;}
.ms2-message-info  {background-color: black !important;}