/*!
 * Bootstrap v3.3.5 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

//
// Load core variables and mixins
// --------------------------------------------------

//@import "variables.less";
//@import "mixins.less";


//
// Buttons
// --------------------------------------------------

// Common styles
.btn-facebook,
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  text-shadow: 0 -1px 0 rgba(0,0,0,.2);
  @shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  .box-shadow(@shadow);

  // Reset the shadow
  &:active,
  &.active {
    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    .box-shadow(none);
  }

  .badge {
    text-shadow: none;
  }
}

// Mixin for generating new styles
.btn-styles(@btn-color: @main-green) {
  #gradient > .vertical(@start-color: @btn-color; @end-color: darken(@btn-color, 12%));
  .reset-filter(); // Disable gradients for IE9 because filter bleeds through rounded corners; see https://github.com/twbs/bootstrap/issues/10620
  background-repeat: repeat-x;
  border-color: darken(@btn-color, 14%);

  &:hover,
  &:focus  {
    background-color: darken(@btn-color, 12%);
    background-position: 0 -15px;
  }

  &:active,
  &.active {
    background-color: darken(@btn-color, 12%);
    border-color: darken(@btn-color, 14%);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: darken(@btn-color, 12%);
      background-image: none;
    }
  }
}

// Common styles
.btn {
  font-family: @headings-font-family;
  text-transform: uppercase;
  // Remove the gradient for the pressed/active state
  &:active,
  &.active {
    background-image: none;
  }
}

// Apply the mixin to the buttons
.btn-facebook { .btn-styles(@fbBlue); text-shadow: 0 1px 0 darken(@fbBlue,20%); border-color: darken(@fbBlue,20%); color: #fff; &:hover{color:#fff;}}
.btn-default { .btn-styles(@btn-default-bg); text-shadow: 0 1px 0 #fff; border-color: #ccc; }
.btn-primary { .btn-styles(@btn-primary-bg); }
.btn-success { .btn-styles(@btn-success-bg); }
.btn-info    { .btn-styles(@btn-info-bg); }
.btn-warning { .btn-styles(@btn-warning-bg); }
.btn-danger  { .btn-styles(@btn-danger-bg); }


//
// Images
// --------------------------------------------------

.thumbnail,
.img-thumbnail {
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}


//
// Dropdowns
// --------------------------------------------------

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  #gradient > .vertical(@start-color: @dropdown-link-hover-bg; @end-color: darken(@dropdown-link-hover-bg, 5%));
  background-color: darken(@dropdown-link-hover-bg, 5%);
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  #gradient > .vertical(@start-color: @dropdown-link-active-bg; @end-color: darken(@dropdown-link-active-bg, 5%));
  background-color: darken(@dropdown-link-active-bg, 5%);
}


//
// Navbar
// --------------------------------------------------

// Default navbar
.navbar-default {
  #gradient > .vertical(@start-color: lighten(@navbar-default-bg, 10%); @end-color: @navbar-default-bg);
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered
  border-radius: 0px;
  border: 0px;
  @shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 5px rgba(0,0,0,.075);
  .box-shadow(@shadow);

  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    #gradient > .vertical(@start-color: darken(@navbar-default-link-active-bg, 5%); @end-color: darken(@navbar-default-link-active-bg, 2%));
    .box-shadow(inset 0 3px 9px rgba(0,0,0,.075));
  }
}
.navbar-brand,
.navbar-nav > li > a {
  text-shadow: 0 1px 0 rgba(0,0,0,.25);
  font-family: @headings-font-family;
  font-size: 1.3em;
}

// Inverted navbar
.navbar-inverse {
  #gradient > .vertical(@start-color: lighten(@navbar-inverse-bg, 10%); @end-color: @navbar-inverse-bg);
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered; see https://github.com/twbs/bootstrap/issues/10257
  border-radius: @navbar-border-radius;
  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    #gradient > .vertical(@start-color: @navbar-inverse-link-active-bg; @end-color: lighten(@navbar-inverse-link-active-bg, 2.5%));
    .box-shadow(inset 0 3px 9px rgba(0,0,0,.25));
  }

  .navbar-brand,
  .navbar-nav > li > a {
    text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  }
}

// Undo rounded corners in static and fixed navbars
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}

// Fix active state of dropdown items in collapsed mode
@media (max-width: @grid-float-breakpoint-max) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a {
    &,
    &:hover,
    &:focus {
      color: #fff;
      #gradient > .vertical(@start-color: @dropdown-link-active-bg; @end-color: darken(@dropdown-link-active-bg, 5%));
    }
  }
}


//
// Alerts
// --------------------------------------------------

// Common styles
.alert {
  text-shadow: 0 1px 0 rgba(255,255,255,.2);
  @shadow: inset 0 1px 0 rgba(255,255,255,.25), 0 1px 2px rgba(0,0,0,.05);
  .box-shadow(@shadow);
}

// Mixin for generating new styles
.alert-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 7.5%));
  border-color: darken(@color, 15%);
}

// Apply the mixin to the alerts
.alert-success    { .alert-styles(@alert-success-bg); }
.alert-info       { .alert-styles(@alert-info-bg); }
.alert-warning    { .alert-styles(@alert-warning-bg); }
.alert-danger     { .alert-styles(@alert-danger-bg); }


//
// Progress bars
// --------------------------------------------------

// Give the progress background some depth
.progress {
  #gradient > .vertical(@start-color: darken(@progress-bg, 4%); @end-color: @progress-bg)
}

// Mixin for generating new styles
.progress-bar-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 10%));
}

// Apply the mixin to the progress bars
.progress-bar            { .progress-bar-styles(@progress-bar-bg); }
.progress-bar-success    { .progress-bar-styles(@progress-bar-success-bg); }
.progress-bar-info       { .progress-bar-styles(@progress-bar-info-bg); }
.progress-bar-warning    { .progress-bar-styles(@progress-bar-warning-bg); }
.progress-bar-danger     { .progress-bar-styles(@progress-bar-danger-bg); }

// Reset the striped class because our mixins don't do multiple gradients and
// the above custom styles override the new `.progress-bar-striped` in v3.2.0.
.progress-bar-striped {
  #gradient > .striped();
}


//
// List groups
// --------------------------------------------------

.list-group {
  border-radius: @border-radius-base;
  .box-shadow(0 1px 2px rgba(0,0,0,.075));
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 darken(@list-group-active-bg, 10%);
  #gradient > .vertical(@start-color: @list-group-active-bg; @end-color: darken(@list-group-active-bg, 7.5%));
  border-color: darken(@list-group-active-border, 7.5%);

  .badge {
    text-shadow: none;
  }
}


//
// Panels
// --------------------------------------------------

// Common styles
.panel {
  .box-shadow(0 1px 2px rgba(0,0,0,.05));
}

// Mixin for generating new styles
.panel-heading-styles(@color) {
  #gradient > .vertical(@start-color: @color; @end-color: darken(@color, 5%));
}

// Apply the mixin to the panel headings only
.panel-default > .panel-heading   { .panel-heading-styles(@panel-default-heading-bg); }
.panel-primary > .panel-heading   { .panel-heading-styles(@panel-primary-heading-bg); }
.panel-success > .panel-heading   { .panel-heading-styles(@panel-success-heading-bg); }
.panel-info > .panel-heading      { .panel-heading-styles(@panel-info-heading-bg); }
.panel-warning > .panel-heading   { .panel-heading-styles(@panel-warning-heading-bg); }
.panel-danger > .panel-heading    { .panel-heading-styles(@panel-danger-heading-bg); }


//
// Wells
// --------------------------------------------------

.well {
  #gradient > .vertical(@start-color: darken(@well-bg, 5%); @end-color: @well-bg);
  border-color: darken(@well-bg, 10%);
  @shadow: inset 0 1px 3px rgba(0,0,0,.05), 0 1px 0 rgba(255,255,255,.1);
  .box-shadow(@shadow);
}

//
// Custom CSS
// --------------------------------------------------
body:not(.home){
  background: #fff url('../img/runners_group_lg.png') top center repeat;
}
.justifyright {text-align: right;}
.justifyfull {text-align: justify;}
.abs {position: absolute;}
.rel {position: relative;}
.green { color: @main-green !important; }
.facebook-blue { color: @fbBlue !important; }
.twitter-blue { color: @twBlue !important; }
.grey { color: @grey !important; }
.offBlack { color: @offBlack !important; }
.title-cta {margin-top: 20px;}
.overflow-hidden {overflow:hidden;}

/* CUSTOM TYPOGRAPHY
-------------------------------------------------- */
a { outline: none !important; }
h1,h2,h3,h4 {
  text-transform: uppercase;
}
.decorated {
    border-top: 1px solid @gray-lighter;
    margin: 0 auto 1em;
    position: relative;
    text-align: center;
    top: 0.65em;
}
.decorated > span {
  background: #fff;
  display: inline-block;
  max-width: calc(100% - 20px);
  padding: 0 20px;
  position: relative;
  top: -0.65em;
}

/* HEADER
-------------------------------------------------- */
.tagline {
  color: @main-green;
  padding-top: 25px;
  font-weight: 700;
  font-size: 1.3em;
}

/* NAVBAR
-------------------------------------------------- */
#navbar-wrapper {
  border-bottom: 3px solid #fff;
}
.navbar-brand {
  text-indent: -9999px;
  position: absolute;
  margin-top: 8px;
  height: 100px;
  width: 164px;
  background: url('../img/rr_logo.png') center left no-repeat;
}
.navbar-clone {
  min-height: 65px !important;
  top: -500px !important;
  border-bottom: 0px;
  
  transition: top 1s ease 0s;
  -webkit-transition: top 1s ease;
  -moz-transition: top 1s ease;
  -o-transition: top 1s ease;
  -ms-transition: top 1s ease;
  
  #navbar-wrapper {
    border-bottom: 0px;
  }
  
  .navbar-nav > li > a {
    padding-bottom: 23px;
    padding-top: 23px;
  }
}
.navbar-show-down {
  top: 0 !important;
  
  .navbar-brand {
    height: 62px !important;
    margin: 2px 10px 2px 0;
    width: 150px !important;
    background-size: contain;
  }
}

/* LOGIN BAR
-------------------------------------------------- */
#login-wrapper {
  background-color: @offBlack;
  color: #fff;
  padding: 10px 0px;
  font-size: 1.4em;
  
  .fa {
    margin: 0px 10px;
    
    &:hover{
      opacity: 0.7;
    }
  }
  .fa-facebook-square{
    border-radius: 5px;
    background: #fff;
  }
}

/* SLIDER
-------------------------------------------------- */
#layerslider {
  z-index: 0;
}
.banner-quote {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.7);
  text-shadow: 0px 0px 15px rgba(0,0,0,0.4);
  color: #fff;
  font-weight: bold;
  z-index: 600;
  font-size: 16px;
  line-height: 35px;
  padding: 15px 20px;
  overflow: hidden;
}



/* CONTENT
-------------------------------------------------- */
.content{margin: 0px auto 30px;}
.content-spacer { padding-top: 100px; }
.three-col {
  -moz-column-count: 3;
  -moz-column-gap: 20px;
  -moz-column-rule-width:  1px;
  -webkit-column-count: 3;
  -webkit-column-gap : 20px;
  -webkit-column-rule-width:  1px;
  text-align: justify !important;
}

/* responsive embedded videos
-------------------------------------------------- */
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}
.embed-responsive.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}
.embed-responsive.embed-responsive-4by3 {
  padding-bottom: 75%;
}
.embed-responsive.google-map {
  padding-bottom: 270px;
}

/* SECTIONS
-------------------------------------------------- */
.section {
  padding-top:40px;
  padding-bottom: 40px;
}
.section:nth-of-type(odd) {
  background: @grey;
  .decorated {
    border-top: 1px solid #fff;
  }
  .decorated > span {
    background: @grey;
  }
}

/* LATEST
-------------------------------------------------- */
#latest-box {
  background: @main-green url('../img/runner_single.png') center center no-repeat;
}
.latest-title {
  background: #fff;
  
  padding: 15px;
  float: left;
  border-right: 3px solid @main-green;
  margin-right: 15px;
  
  h2 {
    font-weight: 400;
    margin: 0;
    color: @main-green;
  }
}

.training-bar{
  background: @offBlack;
  padding: 10px 15px;
  display: block;
  
  .training-title {
    width: 50px;
    float: left;
    color: @gray-light;
    margin-right: 10px;
  }
  .training-activity{
    float: right;
    color: #fff;
    text-align: right;
    
    a {
      color: #fff;
    }
  }
}

.latest-info {
  padding: 0px 15px 15px;
  color: #fff;
  
  h3 {
    margin: 15px 0px;
    font-size: 0.9em;
    color: #fff;
    text-align: center;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0px;
  }
  
  ul li a {
    color: #fff;
  }
}

/* LATEST BAR
-------------------------------------------------- */
#latest-bar {
  margin-bottom: 20px;
  background: @gray-lighter;
}
.latest-rolling {
  display: block;
  padding: 18px 15px;
  font-size: 1.2em;
}
#latest-rolling-bar > div:not(:first-of-type) {
    display: none;
    visibility: hidden;
}
#latest-rolling-bar > div:first-of-type {
left: 100px;
}

/* NEWS
-------------------------------------------------- */
.latest-news-article {
  background: @gray-white url('../img/runners_group.png') center center no-repeat;
  box-shadow: 0px 5px 0px @light-green;
  border-radius: 20px;
  padding: 15px;
  
  &:not(:last-of-type){
    margin-bottom: 15px;
  }
  
  h3, h4 {
    color: @offBlack;
  }
}

/* FACEBOOK FEED
-------------------------------------------------- */
.fb-item {
  padding: 10px;
  border-radius: 10px;
  border: 2px solid @gray-lighter;
  background: #fff;
  
  &:not(:last-of-type){
    margin-bottom: 10px;
  }
  
  .fb-likes{
    background: @main-green;
    color: #fff;
    border-radius: 10px;
    padding: 3px 6px;
  }
  .fb-comments{
    border: 1px solid @main-green;
    color: @main-green;
    border-radius: 10px;
    padding: 3px 6px;
  }
  .fb-date {
    color: @gray-light;
  }
}

/* MESSAGE BOARD
-------------------------------------------------- */
#msg-board {
  margin-top: 30px;
}
.msg-item {
  padding: 10px;
  border-radius: 10px;
  #gradient > .vertical(@start-color: @navbar-default-bg; @end-color: lighten(@navbar-default-bg, 10%));
  color: #fff;
  
  &:not(:last-of-type){
    margin-bottom: 10px;
  }
  
  h4{
    color: #fff;
  }
  .msg-content a {
    color: #fff;
    font-weight: bold;
  }
  .action-btns {
    margin-top: 15px;
  }
  .msg-comments {
    border: 1px solid @main-green;
    background: #fff;
    color: @main-green;
    border-radius: 10px;
    padding: 3px 6px;
  }
  .msg-date {
    color: @gray-lighter;
  }
}



/* NEWS BOXES
-------------------------------------------------- */
#news-boxes-wrapper {
  margin-top: 50px;
  padding: 30px 0px;
  background: rgba(0,0,0,0.08);
  
  a {
    text-decoration: none;
  }
  
  .msg-item {
    border: 2px solid @navbar-default-bg;
  }
  
  .fb-item {
    border: 2px solid @gray-light;
  }
}
#news-box {
  h3 {
    color: @offBlack;
  }
}
.action-btns.abs { margin: 0; bottom: 15px; left:15px; right:15px; }
.news-item {
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  #gradient > .vertical(@start-color: @offBlack; @end-color: lighten(@offBlack, 10%));
  color: #fff;
  height: 100%;
  
  h4{
    color: #fff;
  }
  .action-btns {
    margin-top: 15px;
  }
  .news-date {
    color: @gray-light;
  }
}

/* FEATURES
-------------------------------------------------- */
#features {
  margin-top: 50px;
  
  a {
    text-decoration: none;
  }
}
#features-wrapper {
  background: @offBlack;
  color: #fff;
}
#features-wrapper #features {
  margin-top: 0px;
  padding: 30px 0px;
}
#join {
  padding: 10px 15px;
  border-radius: 20px;
  #gradient > .vertical(@start-color: @gray-dark; @end-color: lighten(@gray-dark, 10%));
  color: #fff;
  font-size: 1.2em;
  box-shadow: 0px 5px 0px @light-green;
  
  &:hover{
    opacity: 0.7;
  }
  
  h4{
    color: #fff;
  }
}
#kit-order {
  padding: 10px 15px;
  border-radius: 20px;
  #gradient > .vertical(@start-color: @gray-lighter; @end-color: lighten(@gray-lighter, 10%));
  color: @offBlack;
  font-size: 1.2em;
  box-shadow: 0px 5px 0px @light-green;
  
  &:hover{
    opacity: 0.7;
  }
  
  h4{
    color: @offBlack;
  }
}

/* GALLERY
-------------------------------------------------- */
.galleryThumb {
  margin-bottom: 15px;  
}
.galleryThumb:hover {
  opacity: 0.8; 
}
.galleryName {
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.7);
  text-shadow: 0px 0px 15px rgba(0,0,0,0.4);
  color: #fff;
  padding: 5px;
}


/* FOOTER
-------------------------------------------------- */
#footer {
  color: @gray-darker;
  
  padding: 20px 5px;
  border-radius: 20px 20px 0px 0px;
  
  p { margin-bottom: 0px; }
}
#footer.home {
  background: @gray-lighter;
  margin-top: 50px;
}
.footer-links {
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    padding-bottom: 20px;
  }
  a { 
      color: @gray-light;
      &::after { content: "\A"; white-space:pre; }
  }
}


/* FORMS
-------------------------------------------------- */
#LastName {
    background-color: #ffffff;
    height: 1px;
    left: 0;
    overflow: hidden;
    position: absolute;
    text-decoration: underline;
    top: -9999px;
    width: 1px;
}

/* RESPONSIVE CSS
-------------------------------------------------- */
//xs down
@media (max-width: ceil((@screen-xs - 1))) {
  
}

//xs - sm
@media (min-width: @screen-xs) and (max-width: ceil((@screen-sm - 1))) {
  #footer p{text-align:center}
  .footer-links img { float: none !important; margin: 0 auto; width:auto;  }
}

//sm down
@media (max-width: ceil((@screen-sm - 1))) {
  .navbar-nav { float: left; margin-right: 0; width: 100%; }
  .navbar-collapse { padding-right: 0px; }
  .nav > li > a,
  .navbar-clone .navbar-nav > li > a {
    padding: 10px 8px;
  }
  
  .logo { text-align: center; margin:  0 auto; }
  
  .dropdown-menu{box-shadow: none;border: none; border-radius: 0px; float: none; position: static; }
  
  .content-spacer { padding-top: 50px; }
  p img { width: 100%; height: auto; }
  
  .banner-slide {
    .banner-quote { font-size: 14px; }
  }
  
  .three-col {
    -moz-column-count: auto;
    -webkit-column-count: auto;
  }
  
  .footer-feature {
    margin-bottom: 30px;
  }
  
}

//sm - md
@media (min-width: @screen-sm) and (max-width: ceil((@screen-md - 1))) {
  .nav > li > a,
  .navbar-clone .navbar-nav > li > a {
    padding: 15px 8px;
    font-size: 0.8em;
  }
  
  .navbar-show-down,
  .navbar-clone {
    min-height: 50px !important;
  }
  
  .navbar-show-down {
    .navbar-brand {
      height: 46px !important;
    }
  }
  
  .navbar-brand {
    margin-top: 8px;
    height: 92px;
    width: 164px;
    background-size: contain;
  }
  
  .ls-layer {
    .banner-quote {
      font-size: 60px;
      padding: 20px;
    }
  }
  
  p img { width: 100%; height: auto; }
  
  .three-col {
    -moz-column-count: 2;
    -webkit-column-count: 2;
  }
  
  .latest-rolling {
    //width:50%;
    font-size: 1em;
    padding: 20px 15px;
    overflow: hidden;
  }
  
  #club-races {
    margin-bottom: 30px;
  }

}

//md - lg
@media (min-width: @screen-md) and (max-width: ceil((@screen-lg - 1))) {
  .nav > li > a { padding: 15px 10px; }
  
  .navbar-brand {
    margin-top: 8px;
    height: 92px;
    width: 164px;
    background-size: contain;
  }
  
  .banner-slide {
    .banner-quote { padding: 20px; font-size: 40px; }
  }
  
  .latest-rolling {
    //width:60%;
    overflow: hidden;
  }
  
}

//lg+
@media (min-width: @screen-lg) {

}